// Simple "lightbox" popup for images
.pop {
	display: none;
}

.pop.show {
	background: rgba(0,0,0,0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 1em;
}

.pop img {
	max-height: 100%;
	max-width: 100%;
}
