body {
  background-color: $color--contrast;
  color: $color--neutral;
  font-family: var(--font-primary);
}
.main {
  @include container;
  padding: 0 1rem;
  margin-top: 5rem;
  @include screen(sm) {
    margin-top: 7.5rem;
  }
}
.content-wrapper {
  margin-bottom: 2rem;
  @include screen(sm) {
    columns: 2;
    column-gap: 3rem;
  }
}
.content {
  margin-bottom: 2rem;
  padding-left: 1rem;
  &__header {
    margin-left: -1rem;
  }
}
