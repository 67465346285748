table {
  width: 100%;
}

thead {
  background-color: $color--blue;
  color: $color--contrast;
  border-bottom: 1px solid $color--neutral;
  margin-bottom: .5rem;

  th {

    &:nth-of-type(1),
    &:nth-of-type(4),
    &:nth-of-type(7) {
      width: 3rem;
    }

    &:nth-of-type(3),
    &:nth-of-type(6) {
      border-right: 1px solid currentColor;

      width: 0;
    }

    &:nth-of-type(2),
    &:nth-of-type(5),
    &:nth-of-type(8) {
      width: 10ch;
    }
  }
}

tr {
  // text-align: center;
  line-height: 1.8;

  &:nth-of-type(even) {
    background-color: hsla(0, 0%, 0%, .05);
    // background: $color--blue;
  }
}

td {

  padding-left: 1rem;

  &:nth-child(3),
  &:nth-child(6) {
    border-right: 1px solid currentColor;
  }
}