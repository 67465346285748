.font-changer {
  font-size: 1.2rem;

  @include screen(sm) {
    font-size: 1.5rem;
  }

  margin-bottom: 5rem;
  line-height: 1.4;

  >* {
    grid-column: 2;
  }

  &__header {
    font-weight: 800;
    font-family: var(--font-header);
    font-size: var(--font-size--section-header, inherit);
  }

  &__list {
    list-style-type: square;
    padding-left: 1rem;
    margin-bottom: 1rem;

    @include screen(sm) {
      padding-left: 0;
      list-style-type: none;
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
    }
  }

  &__list-item {
    @include screen(sm) {
      &:after {
        content: " | ";
        margin-right: 0.5rem;
      }
    }

    &:last-child {
      &:after {
        content: unset;
      }
    }
  }

  &__button {
    background-color: transparent;
    border: 0;
    outline: 0;
    font-family: inherit;
    font-size: var(--font-size--body);
    font-weight: 500;
    cursor: pointer;
    width: auto;
    color: inherit;
    padding: 0;
  }

  &__overlay {
    $transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    transition: $transition;
    clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
    background-color: $color--neutral;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;

    &:after {
      content: "Change That Font!";
      font-family: "Pique";
      color: $color--contrast;
      position: absolute;
      font-size: 10vw;
      top: 50%;
      transform: translateY(-50%) rotate(-15deg) scale(1);
      text-align: center;
      left: 0;
      transition: $transition;
      right: 0;
    }

    &--active {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

      &:after {
        transform: translateY(-50%) rotate(15deg) scale(1.2);
      }
    }
  }

  &__content {
    display: none;
    font-size: 0.875rem;

    @include screen(sm) {
      font-size: 1rem;
    }

    a {
      @include external-link;
    }

    &[data-font="metro-nova"] {
      [font="metro-nova"] & {
        display: block;
      }
    }

    &[data-font="operator"] {
      [font="operator"] & {
        display: block;
      }
    }

    &[data-font="pique-elena"] {
      [font="pique-elena"] & {
        display: block;
      }
    }

    &[data-font="canela"] {
      [font="canela"] & {
        display: block;
      }
    }

    &[data-font="taurus"] {
      [font="taurus"] & {
        display: block;
      }
    }

    &[data-font="smoosh-balto"] {
      [font="smoosh-balto"] & {
        display: block;
      }
    }
  }
}