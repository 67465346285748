.post {
  margin-bottom: 2rem;
  font-size: 1.25rem;
  line-height: 1.4;
  border-bottom: 1px solid $color--neutral;
  padding-bottom: 1rem;
  &__container {
    max-width: 17.5rem;
    margin: 0 auto;
    @include screen(sm) {
      max-width: 54ch;
    }
    &--wide {
      @include screen(sm) {
        max-width: 67rem;
      }
    }
  }
  &__header {
    margin-bottom: 4rem;
  }
  &__hero {
    max-width: 100%;
    margin-bottom: 2rem;
    font-family: $font--iawriter;
    font-feature-settings: 'ss02' on, 'ss03' on;
    font-size: 0.75rem;
    margin-bottom: 2rem;

    @include screen(sm) {
      margin-bottom: 3rem;
    }
    > figcaption {
      max-width: 17.5rem;
      margin: 0 auto;
      font-weight: 450;
      @include screen(sm) {
        max-width: unset;
      }
    }
  }
  &__hero-image {
    margin-bottom: 0.5rem;

    width: 100vw;
    margin-left: calc(50% - 50vw);
    @include screen(sm) {
      margin: 0 auto 0.5rem;
      width: 67rem;
      max-width: 100%;
    }
  }
  &__title {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    @include screen(sm) {
      font-size: 3.25rem;
    }
  }
  &__lede {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 4rem;
    line-height: 1.3;
    @include screen(sm) {
      font-size: 1.75rem;
      margin-bottom: 4rem;
    }
  }
  &__details {
    font-family: $font--iawriter;
    font-feature-settings: 'ss02' on, 'ss03' on;
    font-size: 0.95rem;
    line-height: 1.25;
    margin-bottom: 2.25rem;
    font-weight: 450;
    border-bottom: 1px solid $color--neutral;
    @include screen(sm) {
      padding-bottom: 1rem;
    }
  }
  &__detail {
    margin-bottom: 0.5rem;
  }
  &__detail-header {
    font-weight: 650;
  }
  a {
    text-decoration: underline;
  }
  &__content {
    h2 {
      font-size: 1.7rem;
      font-weight: 800;
      margin-bottom: 0.5em;
    }
    h3 {
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 0.5em;
    }
    strong {
      font-weight: 700;
      font-variant-numeric: oldstyle-nums;

    }
    em {
      font-style: italic;
    }
    p {
      margin-bottom: 1rem;
      font-variant-numeric: oldstyle-nums;

    }
    blockquote {
      font-size: 1.375rem;
      font-weight: 500;
      padding-left: 2rem;
      padding-right: 2rem;
      border-left: 2px solid $color--neutral;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    ul {
      padding-left: 2rem;
      list-style-type: disc;
      margin-bottom: 1rem;
    }
    ol {
      padding-left: 2rem;
      list-style: initial;
      margin-bottom: 1rem;
    }
    img {
      max-width: 100%;
    }
    video {
      max-width: 100%;
      margin-bottom: 0.25rem;
      margin-top: 1.5rem;
      @include screen(sm) {
        margin-bottom: 0.25rem;
        margin-top: 2rem;
      }
    }
    iframe {
      width: 100%;
      max-width: 100%;
      height: 10.5rem;
      margin-bottom: 0.25rem;
      margin-top: 1.5rem;
      @include screen(sm) {
        height: 22rem;
        margin-bottom: 0.25rem;
        margin-top: 1.5rem;
      }
    }
    figure {
      max-width: 100%;
      margin-bottom: 2rem;
      font-family: $font--iawriter;
      font-feature-settings: 'ss02' on, 'ss03' on;
      font-size: 0.8rem;
      font-weight: 450;
      > img {
        margin-bottom: 0.25rem;
      }
    }
  }
}
.enter-the-matrix {
  width: 1.6875rem;
  display: block;
  margin: 2rem auto;
}
