.nav {
  @include container;
  margin-top: 2rem;
  margin-bottom: 6rem;
}
.nav-list {
  $this: &;
  list-style-type: none;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  font-size: 1.5rem;
  grid-gap: 0.5rem;
  &:before {
    content: "|";
    grid-column: 2;
  }
  &__link {
    color: inherit;
    text-decoration: none;
    &--active {
      font-weight: bold;
    }
  }
}
