@mixin container {
  max-width: 67rem;
  margin: 0 auto;
}

@mixin two-column-container {
  display: grid;
  @include screen(sm) {
    grid-column-gap: 3.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @include screen(lg) {
    grid-column-gap: 6.5rem;
  }
}

@mixin external-link {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    svg {
      opacity: 1;
    }
  }
  svg {
    width: 1.5ch;
    opacity: 0.5;
  }
}

@mixin screen($breakpoint) {
  $pt: $breakpoint;
  @if map-has-key($breakpoints, $breakpoint) {
    $pt: map-get($breakpoints, $breakpoint);
  }
  @media screen and (min-width: $pt) {
    @content;
  }
}
