h1 {
  font-size: 4.5rem;
  font-weight: 800;
}

ul,
ol {
  padding: 0;
}
hr {
  border-color: $color--neutral;
  border-width: 1px;
  margin-bottom: 2rem;
  opacity: 0.3;
}

a {
  &[href^="http"],
  &[href^="mailto"] {
    @include external-link;
  }
}

.content {
  break-inside: avoid;
  font-size: 1.2rem;
  @include screen(sm) {
    font-size: var(--font-size--body, 1.5rem);
  }
  // margin-bottom: 1.5em;
  line-height: 1.4;
  grid-column: 1;
  font-weight: 500;
  p {
    margin-block-end: 2rem;
  }
  &__header {
    font-weight: 800;
    font-family: var(--font-header);
    font-size: var(--font-size--section-header);
  }
  &:nth-of-type(n + 3) {
    grid-column: 2;
  }
  &:nth-of-type(1) {
    grid-row-end: span 3;
  }
}

.frac {
  font-feature-settings: "frac" 1;
}

code {
    font-family: monospace;
    background-color: rgba(27, 31, 35, 0.05);
    border-radius: 4px;
    font-size: 90%;
    margin: 0;
    padding: .15em .25em;
}
