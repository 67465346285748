$color--contrast: #F7F7FA;
$color--neutral: #443a45;

$color--blue: #0360D4;
$color--green: #02A541;
$color--orange: #F07601;
$color--red: #EE3A24;
$color--lightblue: #54ADFF;
$color--yellow: #FFB805;
$palette--accents: (
  $color--blue,
  $color--green,
  $color--orange,
  $color--red,
  $color--lightblue,
  $color--yellow,
);

$font--metro: "Metro Nova", sans-serif;
$font--operator: "Operator", sans-serif;
$font--iawriter: "iA Writer Duo Var", "iA Writer Duo", "IBM Plex Mono",
  monospace;
$font--pique: "Pique", cursive;
$font--elena: "Elena", serif;
$font--canela: "Canela", serif;
$font--canelablackletter: "CanelaBlackletter", serif;
$font--taurus: "Taurus", sans-serif;
$font--smoosh: "Smoosh", serif;
$font--balto: "Balto", sans-serif;


$breakpoints: (
  sm: 37.5rem,
  lg: 90rem,
);

html {
  --font-primary: #{$font--metro};
  --font-header: var(--font-primary);
  --font-size--intro-header: 5rem;
  --font-size--intro: 2.4rem;

  &[font="pique-elena"] {
    --font-primary: #{$font--elena};
    --font-header: #{$font--pique};
    --font-size--intro: 3rem;
    --font-size--intro-header: 6rem;
    --font-size--section-header: 2.1rem;

    .intro__header {
      margin-bottom: 0;
    }

    .intro__large {
      margin-block-end: 0;
      letter-spacing: 0.04rem;
      line-height: 3.2rem;
    }

    .content__header {
      letter-spacing: 0.04rem;
    }
  }

  &[font="canela"] {
    --font-primary: #{$font--canela};
    --font-header: #{$font--canelablackletter};
    --font-size--intro-header: 6rem;
    --font-size--intro: 2.6rem;
    --font-size--section-header: 1.8rem;
    --font-size--body: 1.4rem;
  }

  &[font="operator"] {
    --font-primary: #{$font--operator};
    --font-size--intro-header: 5rem;
    --font-size--intro: 1.9rem;
    --font-size--section-header: 1.5rem;
    --font-size--body: 1.35rem;
  }

  &[font="taurus"] {
    --font-primary: #{$font--taurus};
    --font-size--intro-header: 5rem;
    --font-size--intro: 2.3rem;
    --font-size--section-header: 1.5rem;
    --font-size--body: 1.45rem;
  }

  &[font="smoosh-balto"] {
    --font-primary: #{$font--balto};
    --font-header: #{$font--smoosh};
    --font-size--intro: 8.5rem;
    --font-size--intro-header: 15rem;
    --font-size--section-header: 5rem;

    .intro__header {
      margin-bottom: 0;
      margin-top: -5rem;
      letter-spacing: .04rem;
    }

    .intro__large {
      margin-block-end: 0;
      letter-spacing: 0.04rem;
      line-height: 7rem;
      margin-top: -2rem;
    }

    .content__header {
      letter-spacing: 0.04rem;
    }
  }
}