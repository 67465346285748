@font-face {
  font-family: "Metro Nova";
  src: url("../fonts/metro-nova/MetroNovaRegular.woff2") format("woff2"),
    url("../fonts/metro-nova/MetroNovaRegular.woff") format("woff"),
    url("../fonts/metro-nova/MetroNovaRegular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Metro Nova";
  src: url("../fonts/metro-nova/MetroNovaRegularItalic.woff2") format("woff2"),
    url("../fonts/metro-nova/MetroNovaRegularItalic.woff") format("woff"),
    url("../fonts/metro-nova/MetroNovaRegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metro Nova";
  src: url("../fonts/metro-nova/MetroNovaBold.woff2") format("woff2"),
    url("../fonts/metro-nova/MetroNovaBold.woff") format("woff"),
    url("../fonts/metro-nova/MetroNovaBold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Metro Nova";
  src: url("../fonts/metro-nova/MetroNovaBoldItalic.woff2") format("woff2"),
    url("../fonts/metro-nova/MetroNovaBoldItalic.woff") format("woff"),
    url("../fonts/metro-nova/MetroNovaBoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metro Nova";
  src: url("../fonts/metro-nova/MetroNovaMedium.woff2") format("woff2"),
    url("../fonts/metro-nova/MetroNovaMedium.woff") format("woff"),
    url("../fonts/metro-nova/MetroNovaMedium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Metro Nova";
  src: url("../fonts/metro-nova/MetroNovaMediumItalic.woff2") format("woff2"),
    url("../fonts/metro-nova/MetroNovaMediumItalic.woff") format("woff"),
    url("../fonts/metro-nova/MetroNovaMediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metro Nova";
  src: url("../fonts/metro-nova/MetroNovaBlack.woff2") format("woff2"),
    url("../fonts/metro-nova/MetroNovaBlack.woff") format("woff"),
    url("../fonts/metro-nova/MetroNovaBlack.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Duo Var";
  src: url("../fonts/ia-writer-duo/iAWriterDuoV.ttf") format("truetype");
  font-weight: 400 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Duo Var";
  src: url("../fonts/ia-writer-duo/iAWriterDuoV-Italic.ttf") format("truetype");
  font-weight: 400 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Duo";
  src: url("../fonts/ia-writer-duo/iAWriterDuoS-Regular.woff2") format("woff2"),
    url("../fonts/ia-writer-duo/iAWriterDuoS-Regular.woff") format("woff");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Duo";
  src: url("../fonts/ia-writer-duo/iAWriterDuoS-Bold.woff2") format("woff2"),
    url("../fonts/ia-writer-duo/iAWriterDuoS-Bold.woff") format("woff");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Duo";
  src: url("../fonts/ia-writer-duo/iAWriterDuoS-Italic.woff2") format("woff2"),
    url("../fonts/ia-writer-duo/iAWriterDuoS-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "iA Writer Duo";
  src: url("../fonts/ia-writer-duo/iAWriterDuoS-BoldItalic.woff2") format("woff2"),
    url("../fonts/ia-writer-duo/iAWriterDuoS-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Pique";
  src: url("../fonts/pique/PiqueWeb.woff2") format("woff2"),
    url("../fonts/pique/PiqueWeb.woff") format("woff");
  font-display: swap;
  font-weight: bold;
}

@font-face {
  font-family: "Elena";
  src: url("../fonts/elena/ElenaWebBasicMedium.woff2") format("woff2"),
    url("../fonts/elena/ElenaWebBasicMedium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Canela";
  src: url("../fonts/canela/CanelaText-RegularNo2-Web.woff2") format("woff2"),
    url("../fonts/canela/CanelaText-RegularNo2-Web.woff") format("woff");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "CanelaBlackletter";
  src: url("../fonts/canela/CanelaBlackletter-MediumTitling.woff2") format("woff2"),
    url("../fonts/canela/CanelaBlackletter-MediumTitling.woff") format("woff");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Operator";
  src: url("../fonts/operator/OperatorSSm-Bold.woff2") format("woff2");
  src: url("../fonts/operator/OperatorSSm-Bold.woff") format("woff");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Operator";
  src: url("../fonts/operator/OperatorSSm-Book.woff2") format("woff2");
  src: url("../fonts/operator/OperatorSSm-Book.woff") format("woff");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Taurus";
  src: url("../fonts/taurus/TaurusGrotesk-HeavyItalic.woff2") format("woff2");
  src: url("../fonts/taurus/TaurusGrotesk-HeavyItalic.woff") format("woff");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Taurus";
  src: url("../fonts/taurus/TaurusGrotesk-Regular.woff2") format("woff2");
  src: url("../fonts/taurus/TaurusGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Balto";
  src: url("../fonts/balto/baltoweb-medium.woff") format("woff");
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: "Smoosh";
  src: url("../fonts/smoosh/smoosh4web-bold.woff2") format("woff2");
  src: url("../fonts/smoosh/smoosh4web-bold.woff") format("woff");
  font-weight: bold;
  font-display: swap;
}