.post-list {
  @include two-column-container;

  &__section-header {
    font-size: 1.7rem;
    font-weight: 800;
    margin-bottom: 1em;
    font-weight: bold;
  }
}
.post-list-item {
  margin-bottom: 6rem;
  &__link {
    color: inherit;
    text-decoration: none;
    display: block;
  }
  &__image {
    max-width: 100%;
    margin-bottom: 1rem;
    aspect-ratio: 16 / 9;
    width: 100%;
  }
  &__title {
    font-size: 1.75rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  &__lede {
    margin-bottom: 1rem;
    line-height: 1.5rem;
    font-size: 1.1875rem;
  }
  &__details {
    font-size: 0.875rem;
    line-height: 1.4;
    font-weight: 450;
    font-family: $font--iawriter;
    font-feature-settings: 'ss02' on, 'ss03' on;
  }
  &__detail {
  }
  &__detail-header {
    font-weight: 650;
  }
}
