.intro {
  grid-column-end: span 2;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 3.5rem;
  font-size: 2rem;
  line-height: 1.3;
  font-family: var(--font-header);
  display: flex;
  flex-direction: column-reverse;
  &--writing {
    @include screen(sm) {
      margin-bottom: 8.5rem;
    }
  }

  @include screen(sm) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
  }

  &__content {
    grid-column-end: span 5;
    [data-doug] {
      cursor: pointer;
      @each $color in $palette--accents {
        $i: index($palette--accents, $color);
        &:nth-of-type(#{$i}) {
          color: $color;
        }
      }
    }
  }
  &__header {
    margin-bottom: 0.5em;
    position: relative;
    font-size: var(--font-size--intro-header);
    &--small {
      font-size: inherit;
    }
  }
  &__large {
    margin-block-end: 1em;
    font-size: var(--font-size--intro);
    font-weight: bold;
  }
  &__dougs {
    grid-column-end: span 4;
    // justify-self: end;
    position: relative;
    align-self: stretch;
  }
  &__image {
    border-radius: 100%;
    max-width: 60%;
    @include screen(sm) {
      max-width: 100%;
      top: 50%;
      right: 0;
      max-height: 20rem;
      transform: translateY(-50%);
      position: absolute;
    }
    &--inactive {
      display: none;
    }
  }
}
